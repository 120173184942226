
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
    form_action: {
      type: String,
    },
  },
  setup (props) {
    const store = useStore()
    const form = ref(props.data)
    const selectedItems = ref([])
    const getDistricts = () => {
      store.dispatch('Option/getAllDistricts')
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const all_districts = computed(() => store.getters['Option/all_districts'])
    onMounted(getDistricts)
    return {
      form,
      all_districts,
      selectedItems,
      filterOption
    }

  },
})
