
import Form from './Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive } from 'vue'

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form = reactive({
      id: '',
      plant_code: '',
      plant_name: '',
      plant_address: '',
      plant_districts: [{
        district_id: ''
      }]
    })
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()

    const fields = ref([
      {
        title: 'Plant Code',
        dataIndex: 'plant_code',
      },
      {
        title: 'Plant Name',
        dataIndex: 'plant_name',
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('Plant/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, plant_code, plant_name, plant_address, plant_districts} = toRefs(form)
      id.value = record.id
      plant_code.value = record.plant_code
      plant_name.value = record.plant_name
      plant_address.value = record.plant_address
      var list: any[] = []
      record.plant_districts.forEach((item: any) => {
        list.push(item.district_id)
      })
      plant_districts.value = list
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, plant_code, plant_name, plant_address, plant_districts} = toRefs(form)
      id.value = ''
      plant_code.value = ''
      plant_name.value = ''
      plant_address.value = ''
      plant_districts.value = [{
        district_id: ''
      }]
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('Plant/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload = {}) => {
      store
          .dispatch('Plant/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('Plant/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['Plant/items'])
    const loading = computed(() => store.getters['Plant/status'])

    onMounted(index)

    return {
      loading,
      items,
      fields,
      visible,
      form,
      form_action,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
